








































































import {Component, Watch} from 'vue-property-decorator';
import TextFieldPassword from '@/components/profile/privacy-security/TextFieldPassword.vue';
import TextFieldEmail from '@/components/profile/privacy-security/TextFieldEmail.vue';
import {Action} from 'vuex-class';
import {applicationStore} from '@/store/modules/application';
import {profileStore} from '@/store/modules/profile';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import ContainerCalc from '@/components/mixins/ContainerCalc';

@Component({
  name: 'privacy-and-policy',
  components: {TextFieldEmail, TextFieldPassword}
})
export default class PrivacyAndSecurity extends mixins(Notifications, ContainerCalc) {
  public password: string = ''
  public newPassword: string = ''
  public confirmedPassword: string = ''
  public email: string = ''
  public busyReset: boolean = false

  @Action('requestPasswordReset', {namespace: 'auth'}) public requestPasswordReset

  get user() {
    return profileStore.t2bUser
  }

  get errorMessage() {
    return profileStore.errorProfile
  }

  get successMessage() {
    return profileStore.success
  }

  get busy() {
    return profileStore.savingProfile
  }

  @Watch('errorMessage')
  public onErrorChanged(after, before) {
    if (!after) {
      return
    }
    this.showIssue(after)
  }

  @Watch('successMessage')
  public onSuccessChanged(after, before) {
    if (!after) {
      return
    }
    this.showInfo(after)
  }

  public async onUpdate() {
    if (this.busy) {
      return
    }
    const form: any = this.$refs.updatePassword
    if (!form.validate()) {
      return
    }
    const {password, newPassword} = this
    const ok = await profileStore.updatePassword({password, newPassword})
    if (ok) {
      form.reset()
    }
  }

  public async onReset() {
    this.busyReset = true
    const form: any = this.$refs.resetPassword
    if (!form.validate()) {
      this.busyReset = false
      return
    }
    if (this.email !== this.user.email) {
      this.showIssue('Entered email is not equal to user\'s registration email')
      this.busyReset = false
      return
    }
    const ok = await this.requestPasswordReset(this.email)
    if (ok) {
      await applicationStore.signOut()
      await this.$router.push({name: 'auth'});
    }
    form.reset()
    this.busyReset = false
    this.showInfo('Reset password email has been sent')
  }

  public mounted() {
    this.calcHeight(this.$refs.privacyAndSecurityConntainer)
  }
}
