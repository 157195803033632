

















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'text-field-password'})
export default class TextFieldPassword extends Vue {
  @Prop() value
  @Prop({type: String}) label
  @Prop({type: Boolean, default: false}) matcher
  @Prop({type: Boolean, default: true}) validate
  @Prop({type: String, default: ''}) fieldAttribute
  @Prop({type: String, default: 'off'}) autocomplete
  @Prop({type: String, default: ''}) hint
  @Prop({type: Boolean, default: false}) eye

  inputType: string = 'password'

  get type() {
    return this.inputType
  }

  get eyeIcon() {
    if (this.eye) {
      return this.inputType === 'password' ? 'mdi-eye-off mr-3' : 'mdi-eye mr-3'
    }
    return ''
  }

  get fieldValue() {
    return this.value
  }

  set fieldValue(value: string) {
    this.$emit('input', value)
  }

  get rules() {
    if (!this.validate) {
      return []
    }
    const ctx: any = this //todo: temporary solution for Vue plugin and TypeScript
    const checks = [ctx.$validations.required, ctx.$validations.password];
    if (this.matcher) {
      checks.push(ctx.$validations.passwordMatch)
    }
    return checks
  }

  onClickEye() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password'
  }
}
